/**
 * This file serves only the homepage, its done like this so it can be served from /locale
 */
import DynamicPage from "@/components/dynamic-page";
import { ENABLED_LOCALES } from "@/constants";
import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import { formatLocale } from "@/utils/helpers";

export const getStaticPaths = async () => {
  // Create a homepage for each of the enabled locales.
  const paths = ENABLED_LOCALES.map((locale) => ({
    params: {
      locale: locale.toLowerCase(),
    },
  }));

  return {
    paths,
    fallback: "blocking",
  };
};

export async function getStaticProps(context: any) {
  const locale = formatLocale(context.params.locale, true);

  if (!locale || locale === "pillar") {
    return {
      notFound: true,
    };
  }

  // Get contentful published page data.
  const liveData = await HeadlessFetcher.getPageData({
    locale,
    slug: "home",
    env: "live",
    contentful: "published",
    strip: true,
  });

  if (!liveData) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: liveData,
    },
  };
}

const LiveHomepage = ({ data }: any) => {
  return <DynamicPage data={data} />;
};

export default LiveHomepage;
