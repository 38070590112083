import { PageEntry } from "@/services/fetchers/headless-fetcher";
import { CmcSchemas, SeoHandler } from "@/services/seo/schemas";
import SEO from "@/services/seo/seo-service";
import { PageInterface } from "@/types/page";
import SupNavigator from "@/utils/sup-navigator";
import ComponentResolver from "@/utils/system/component-resolver";
import Page from "@/utils/system/page";
import { ContentfulCollection } from "contentful";
import { Entry } from "contentful-management";
import React from "react";

export default function DynamicPage({
  data,
}: {
  data: ContentfulCollection<PageEntry & Entry>;
}): React.JSX.Element {
  const page: PageInterface = new Page(data);
  const seo: SeoHandler = new SeoHandler(page);

  return (
    <>
      {/* Head */}
      <SEO pageData={seo} />

      {/* Body */}
      {page.components?.map((component: any) => {
        return <ComponentResolver key={component.sys.id} data={component} />;
      })}

      {/* Footnote navigator */}
      <SupNavigator />
      {/* Schemas */}
      <CmcSchemas pageData={seo} />
    </>
  );
}
