import { ArticleJsonLdProps, ArticleJsonLd, BrandJsonLd, OrganizationJsonLd, WebPageJsonLd } from "next-seo";
import { ArticleAuthor, MetaTag } from "next-seo/lib/types";
import { PageInterface } from "@/types/page";
import React from "react";
import { SeoInterface, SeoProps } from "@/types/seoInterface";
import seoData from "@/services/seo/seo-data.json" assert { type: "json" };

export function CmcSchemas({ pageData }: SeoProps): React.JSX.Element {
  // console.log('Schemas.tsx line 9 function CmcSchemas ' + pageData.getSchemaTypes());//console.dir(pageData);

  return (
    <>
      {pageData.getSchemaTypes()?.map((schemaType: string, index: number) => {
        if (schemaType == "webpage") {
          return <WebPageSchema key={index} pageData={pageData} />;
        }
        if (schemaType == "organisation") {
          return <OrganisationSchema key={index} pageData={pageData} />;
        }
        if (schemaType == "article") {
          return <ArticleSchema key={index} pageData={pageData} />;
        }
      })}
    </>
  );
}

// learn article pages only, web page schema should not be on this page
function ArticleSchema({ pageData }: SeoProps): React.JSX.Element {
  //console.log('Schemas.js ' + data.data.schemaType);console.dir(data);
  // check if author exists
  // check if images exists
  return (
    <>
      <ArticleJsonLd
        useAppDir={false}
        url={pageData.getCanonicalUrl()}
        title={pageData.getPageTitle()}
        images={pageData.getEntryImage()}
        datePublished={pageData.getEntryPublishedDate()}
        authorName={pageData.getAuthors()}
        description={pageData.getPageDescription()}
        publisherName={pageData.getSiteName()}
        publisherLogo={pageData.getSiteLogo()}
        isAccessibleForFree={true}
      />
    </>
  );
}

// only on the home page
function OrganisationSchema({ pageData }: SeoProps): React.JSX.Element {
  // console.log(`schemas organisationSchema locale: ${pageData.data.locale.toLowerCase()}`);
  if (pageData.data.locale.toLocaleLowerCase() != "en-gb") {
    // console.log(`not generating organisation schema`);
    return <></>;
  }

  return (
    <>
      <OrganizationJsonLd
        name={pageData.getSiteName()}
        url={pageData.getSiteUrlRegional()}
        logo={pageData.getSiteLogo()}
        description={seoData.description.default}
        actionableFeedbackPolicy="https://www.cmcmarkets.com/en-gb/important-information"
        feesAndCommissionsSpecification="https://www.cmcmarkets.com/en-gb/trading-costs"
        legalName={pageData.getSiteName("legal")}
        foundingDate="1989"
        address={getAddress()}
        brand={getBrand({pageData})}
      />
    </>
  );
}

// all pages apart from article pages
function WebPageSchema({ pageData }: SeoProps): React.JSX.Element {
  // https://cmcmarkets.atlassian.net/browse/WF-28220
  //console.log('WebPageSchema.js');console.dir(data);
  return (
    <>
      <WebPageJsonLd
        useAppDir={false}
        id={pageData.getCanonicalUrl()}
        name={pageData.getPageTitle()}
        description={pageData.getPageDescription()}
        url={pageData.getCanonicalUrl()}
        publisher={pageData.getPublisher()}
        copyrightYear={pageData.getCopyrightYear()}
      />
    </>
  );
}

function getAddress(): object {
  // Address type
  return {
    type: "PostalAddress",
    postalCode: "EC3A 7BX",
    streetAddress: "CMC Markets, 133 Houndsditch",
    addressLocality: "London",
    addressCountry: "GB",
    telephone: "020 7170 8200",
  };
}

function getAwards({ pageData }: SeoProps): string[] {
  //return seoData.awards.default;
  //const region = pageData.data.locale.toLocaleLowerCase();
  const awards = getSeoDataByKey({key: "awards", region: pageData.data.locale.toLocaleLowerCase()});
  if (Array.isArray(awards) === false) {
    return [];
  }

  if (awards.length == 0) {
    return [];
  }

  return awards;
}

// usually Organization, Person, Product or Service schema
function getBrand({ pageData }: SeoProps): object {
  return {
    id: pageData.getSiteUrlRegional(),
    type: "organization",
    logo: pageData.getSiteLogo(),
    name: pageData.getSiteName(),
    description: pageData.getPageDescription()
  };
}

function getContactPoint({ pageData }: SeoProps): object {
  return {
    telephone: seoData.contactPoint.default.telephone
  };
}

function getSeoDataByKey({key, region = "default"}: {key: string, region: string}): object|string[] {
  if (region == 'en-gb') {
    region = "default";
  }

  let data = {};
  key = `${key}.${region}`;
//console.dir(seoData);
  try {
    data = eval(`seoData.${key}`);
  } catch (e) {
    console.error(`seoData key: ${key} region: ${region} not found`);
    console.dir(e);
    data = {};
  }

  return data;
}

export class SeoHandler implements SeoInterface {
  public data: PageInterface;
  private nofollow: boolean;
  private noindex: boolean;

  constructor(pageData: PageInterface) {
    //console.log('class SeoHandler line 168 schemas.tsx ');console.dir(pageData);
    this.data = pageData;
    // default value is "nofollow, noindex" > WF-28210
    this.nofollow = true; //this would set "index,nofollow" if noindex is set to true
    this.noindex = true; //this would set "noindex,follow" if nofollow is set to false
    if (typeof pageData.seoIndex != "undefined") {
      let seoDirectives = pageData.seoIndex.split(",");
      this.nofollow = seoDirectives[1] == "nofollow";
      this.noindex = seoDirectives[0] == "noindex";
      //console.log(`nofollow: ${this.nofollow} | noindex: ${this.noindex}`);
    }
  }

  public getAdditionalMetaTags(): MetaTag[] {
    let metaTags = [];
    // it can have property, content, httpEquiv, name. content is required
    metaTags.push({
      name: "twitter:card",
      content: "summary",
    });
    metaTags.push({
      name: "twitter:creator",
      content: "@cmcmarkets",
    });
    metaTags.push({
      name: "twitter:site",
      content: "@cmcmarkets",
    });

    return metaTags;
  }

  public getAuthors(): ArticleAuthor | ArticleAuthor[] {
    return [
      {
        name: "Michael Hooley",
        url: "https://www.cmcmarket.com/groups/author/hooley",
      },
    ];
  }

  public getCanonicalUrl(): string {
    // BASE_URL would provide the domain name for the FQDN
    if (this.data.slug == "home" || this.data.slug == "undefined") {
      return this.getSiteUrlRegional();
    } else {
      //return `${this.getSiteUrlRegional()}/${this.data.slug}`;
      return this.getSiteUrlRegional() + '/' + this.data.slug;
    }
  }

  public getCopyrightYear(): string {
    //make it dynamic later
    let date = new Date();
    return date.getFullYear().toString();
  }

  public getEntryFirstPublishedDate(): string {
    // getting first published at from ['sys']['firstPublishedAt']
    //return '2024-10-11 14:23:00';
    return this.data?.items?.[0]?.sys?.firstPublishedAt || "";
  }

  public getEntryImage(): ArticleJsonLdProps["images"] {
    // need to implement, get the featured image in different sizes
    return [];
  }

  public getEntryPublishedDate(): string {
    // getting first published at from ['sys']['firstPublishedAt']
    //return '2024-10-11 14:23:00';
    return this.data?.items?.[0]?.sys?.publishedAt || "";
  }

  public getNoFollowStatus(): boolean {
    return this.nofollow;
  }

  public getNoIndexStatus(): boolean {
    return this.noindex;
  }

  // we need method to include details to openGraph
  // https://www.npmjs.com/package/next-seo#open-graph
  public getOpenGraphData(): object {
    // twitter will read og:title, og:image, og:description, so no need to add them
    // https://www.npmjs.com/package/next-seo/v/4.24.0#twitter
    return {
      type: "website",
      url: this.getCanonicalUrl(),
      title: this.getPageTitle(),
      description: this.getPageDescription(),
      images: [],
    };
  }

  public getPageDescription(): string {
    return this.data.description;
  }

  public getPageTitle(): string {
    return this.data.title;
  }

  public getPublisher(): object {
    return {
      type: "organization",
      name: this.getSiteName()
    }
  }

  public getSchemaTypes(): string[] {
    if (this.data.slug == 'home') {
      return ['webpage', 'organisation'];
    }
    return ['webpage'];
  }

  public getSiteLogo(): string {
    //"https://www.cmcmarkets.com/logo.svg"
    return this.getSiteUrlRegional() + "/_next/image?url=https%3A%2F%2Fcdn.cmcmarkets.com%2Fneptune%2Fimages%2Fcmc-logo-navy-square.png&w=128&q=75";
  }

  public getSiteName(type = "non-legal"): string {
    if (type === "legal") {
      return "CMC Markets UK plc";
    }

    return "CMC Markets";
  }

  /**
   * return site url with the region at the end. example: https://www.cmcmarkets.com/en-gb
   */
  public getSiteUrlRegional(): string {
    return process.env.BASE_URL + '/' + this.data.locale.toLocaleLowerCase();
  }

}
