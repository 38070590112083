import { NextSeo } from "next-seo";
import React from "react";
import { SeoProps } from "@/types/seoInterface";


export default function SEO({ pageData }: SeoProps): React.JSX.Element {
  //console.log(`seo-service pageData type: ${pageData.constructor.name}`);
  // schema is handled by Schemas, pass the SeoHandler instance like this <Schemas pageData={seo} />
  return (
    <>
      <NextSeo
        title={pageData.getPageTitle()}
        description={pageData.getPageDescription()}
        canonical={pageData.getCanonicalUrl()}
        nofollow={pageData.getNoFollowStatus()}
        noindex={pageData.getNoIndexStatus()}
        openGraph={pageData.getOpenGraphData()}
        additionalMetaTags={pageData.getAdditionalMetaTags()}
      />
    </>
  );
}
