import { isProd } from "@/constants";
import React, { useState, useEffect, useCallback } from "react";

const superscriptNumbers = [
  "¹",
  "²",
  "³",
  "⁴",
  "⁵",
  "⁶",
  "⁷",
  "⁸",
  "⁹",
  "¹⁰",
  "¹¹",
  "¹²",
  "¹³",
  "¹⁴",
  "¹⁵",
];

const SupNavigator = () => {
  const [supElements, setSupElements] = useState<Element[]>([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  // Find all elements with superscript numbers
  const findSupElements = useCallback((node: Node): Element[] => {
    let elements: Element[] = [];
    if (node.nodeType === Node.TEXT_NODE) {
      superscriptNumbers.forEach((number) => {
        if (node.textContent?.includes(number)) {
          elements.push(node.parentElement!);
        }
      });
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach((child) => {
        elements = elements.concat(findSupElements(child));
      });
    }
    return elements;
  }, []);

  // Set the sup elements
  useEffect(() => {
    const elements = findSupElements(document.body);
    setSupElements(elements);
  }, [findSupElements, currentIndex]);

  // Navigate through the sup elements using left & right arrow keys
  const navigate = useCallback(
    (direction: "increment" | "decrement") => {
      setCurrentIndex((prevIndex) => {
        if (direction === "increment") {
          return Math.min(prevIndex + 1, supElements.length - 1);
        } else {
          return Math.max(prevIndex - 1, 0);
        }
      });
      setIsOpen(true);
    },
    [supElements.length]
  );

  // Remove highlights from the sup elements
  const removeHighlights = useCallback(() => {
    const paragraphs = document.querySelectorAll(".bg-yellow-500");
    paragraphs.forEach((paragraph) => {
      paragraph.classList.remove("bg-yellow-500");
    });
    setIsOpen(false);
  }, []);

  // Navigation keyboard event listener
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        navigate("increment");
      } else if (event.key === "ArrowLeft") {
        navigate("decrement");
      } else if (event.key === "z") {
        removeHighlights();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate, removeHighlights]);

  useEffect(() => {
    if (supElements.length > 0) {
      supElements.forEach((element, index) => {
        if (index === currentIndex) {
          (element as HTMLElement).classList.add("bg-yellow-500");
          (element as HTMLElement).scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          (element as HTMLElement).classList.remove("bg-yellow-500");
        }
      });
    }
  }, [currentIndex, supElements]);

  if (isProd) return null;

  return (
    <>
      {isOpen && supElements.length > 0 && (
        <div className="fixed bottom-4 left-0 right-0 z-50 mx-4 max-w-2xl rounded border border-white-90 bg-white/80 p-2 text-center text-xs xs:mx-auto">
          <p className="mb-0">
            Use{" "}
            <kbd
              className="cursor-pointer rounded border border-gray-400 px-1 py-0.5"
              onClick={() => {
                navigate("decrement");
              }}
            >
              ←
            </kbd>{" "}
            and{" "}
            <kbd
              className="cursor-pointer rounded border border-gray-400 px-1 py-0.5"
              onClick={() => {
                navigate("increment");
              }}
            >
              →
            </kbd>{" "}
            keys to navigate through dynamic footnotes. Press{" "}
            <kbd
              className="cursor-pointer rounded border border-gray-400 px-1 py-0.5"
              onClick={() => {
                removeHighlights();
              }}
            >
              z
            </kbd>{" "}
            to close.
          </p>
          <p>
            Currently viewing footnote <strong>{currentIndex + 1}</strong> of{" "}
            <strong>{supElements.length}</strong>
          </p>
        </div>
      )}
    </>
  );
};

export default SupNavigator;
